html,
body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none !important;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* To prevent "light-blue" background color in MUI input elements */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  box-shadow: 0 0 0 1000px white inset;
}
